import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Header from "../components/header"
import portfolios from "../content/portfolios.json"
import { useTransition, animated, useSpring } from "react-spring"

const Portfolio = propss => {
  const data = useStaticQuery(graphql`
    {
      allImageSharp {
        edges {
          node {
            fluid {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `)

  const transitions = useTransition(portfolios, portfolio => portfolio.title, {
    from: { transform: "translate3d(0,-10rem,0) scale(1.2)" },
    enter: { transform: "translate3d(0,0px,0) scale(1)" },
    leave: { transform: "translate3d(0,-10rem,0) scale(1.2)" },
  })

  const [props, set, stop] = useSpring(() => ({
    opacity: 0,
    config: { friction: 100 },
  }))

  const props2 = useSpring({
    height: "7rem",
    from: { height: "0rem" },
    config: { friction: 80 },
  })

  set({ opacity: 1 })

  return (
    <Layout>
      <div className="portfolios">
        <Header path={propss.path} />
        <div className="banner">
          <div className="banner__content">
            <animated.div style={props}>
              <h1 className="banner__heading text-center">
                We make things happen.
              </h1>
            </animated.div>

            <p className="banner__description">
              Conversion oriented UX, pixel perfect UI & clean front-end code.
            </p>
          </div>
          <div className="banner__recent">
            <animated.h3 style={props}>Recent Work</animated.h3>

            <animated.div className="verticle" style={props2}></animated.div>
          </div>
        </div>

        <div className="portfolios__list">
          <div className="container pl-0 pr-0">
            <div className="row justify-content-around portfolios__listcontainer">
              {transitions.map(({ item, props, key }) => {
                console.log("PORTFOLIO", item)
                const img = data.allImageSharp.edges.filter(image =>
                  image.node.fluid.originalName.includes(item.img)
                )

                return (
                  <animated.div
                    className="col-12 col-md-4"
                    key={key}
                    style={props}
                  >
                    <div className="portfoliocard shadow">
                      <Img
                        style={{ height: "100%" }}
                        fluid={img[0].node.fluid}
                        alt=""
                      />
                      <div className="portfoliocard__content">
                        <h2>{item.title}</h2>
                        <p className="text-center">{`${item.description}`}</p>
                      </div>
                    </div>
                  </animated.div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Portfolio
